import React from "react";
import Terms, { TermsItem, TermsTitle, TermsContent } from "../terms";

const TermsWrap = () => {
    return (
        <Terms classOption="accordion-style2 no-bg">
            <TermsItem id="one">
                <TermsTitle id="one">
                    <span className="question-tag">TC:</span>Acceptance of Terms
                </TermsTitle>
                <TermsContent id="one">
                    By accessing our site, you agree to be bound by these terms
                    and conditions.
                </TermsContent>
            </TermsItem>
            <TermsItem id="two">
                <TermsTitle id="two">
                    <span className="question-tag">TC:</span>User
                    Responsibilities
                </TermsTitle>
                <TermsContent id="two">
                    You agree to use the site only for lawful purposes and in a
                    way that does not infringe the rights of others.
                </TermsContent>
            </TermsItem>
            <TermsItem id="three">
                <TermsTitle id="three">
                    <span className="question-tag">TC:</span>Intellectual
                    Property
                </TermsTitle>
                <TermsContent id="three">
                    All content on this site, including text, graphics, logos,
                    and images, is the property of BunnySpire LLC and is
                    protected by copyright laws.
                </TermsContent>
            </TermsItem>
            <TermsItem id="four">
                <TermsTitle id="four">
                    <span className="question-tag">TC:</span>Limitation of
                    Liability
                </TermsTitle>
                <TermsContent id="four">
                    BunnySpire LLC is not liable for any direct, indirect,
                    incidental, or consequential damages arising from the use of
                    this site.
                </TermsContent>
            </TermsItem>
            <TermsItem id="five">
                <TermsTitle id="five">
                    <span className="question-tag">TC:</span>Changes to Terms
                </TermsTitle>
                <TermsContent id="five">
                    We may update these terms from time to time. It is your
                    responsibility to review them periodically.
                </TermsContent>
            </TermsItem>
            <TermsItem id="six">
                <TermsTitle id="six">
                    <span className="question-tag">TC:</span>Governing Law
                </TermsTitle>
                <TermsContent id="six">
                    These terms are governed by the laws of New Mexico.
                </TermsContent>
            </TermsItem>
        </Terms>
    );
};

export default TermsWrap;
