import React, { Fragment, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import FaceAutoCapture from "../selfie/FaceAutoCapture";
import PhotoResult from "../selfie/PhotoResult";

const ApplyForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });

    const [photoUrl, setPhotoUrl] = useState();
    const [startSelfe, setStartSelfie] = useState(false);

    const handlePhotoTaken = (image, data, content) => {
        const imageUrl = URL.createObjectURL(image);
        setPhotoUrl(imageUrl);
    };

    const handleFaceCapturePhotoTaken = (image, data) => {
        handlePhotoTaken(image, data);
    };

    const handleError = useCallback((error) => {
        console.log(error);
        alert(error);
    }, []);

    const handleBackClick = () => {
        // setPhotoUrl(undefined);
        // setStep(Step.SELECT_COMPONENT);
    };

    return (
        <Fragment>
            <form
                id="applyform"
                className="row"
                action="https://getform.io/f/5d1d9050-7207-4abd-a35b-c3b2eeb7dbc4"
                method="POST"
                encType="multipart/form-data"
            >
                <div className="col-12 col-sm-6 mb-7">
                    <label htmlFor="name" className="form-label">
                        Full Name*
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Your Name*"
                        ref={register({ required: "Name is required" })}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <label htmlFor="email" className="form-label">
                        Email*
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Your Email*"
                        ref={register({
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "invalid email address",
                            },
                        })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <label htmlFor="phone" className="form-label">
                        Phone*
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Your Phone Number*"
                        ref={register({
                            required: "Phone number is required",
                            pattern: {
                                value: /^[0-9]/i,
                                message: "invalid email address",
                            },
                        })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <label htmlFor="resume" className="form-label">
                        Resume*
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        id="resume"
                        name="resume"
                        placeholder="Your Resume*"
                        ref={register({ required: "Resume is required" })}
                    />
                    {errors.resume && <p>{errors.resume.message}</p>}
                </div>
                {/* <div className="col-12 col-sm-6 mb-7">
                    <button
                        className="form-control"
                        onClick={(e) => {
                            e.preventDefault();
                            setStartSelfie(true);
                        }}
                    >
                        Take Your Selfie
                    </button>
                </div>
                {startSelfe && (
                    <FaceAutoCapture
                        onPhotoTaken={handleFaceCapturePhotoTaken}
                        onError={handleError}
                        onBackClick={handleBackClick}
                    />
                )}
                {photoUrl && <PhotoResult photoUrl={photoUrl} />}
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="file"
                        className="form-control"
                        id="selfie"
                        name="selfie"
                        value={photoUrl}
                        hidden
                    />
                </div> */}
                <div className="col-12 mb-9">
                    <label htmlFor="cover_letter" className="form-label">
                        Cover Letter (Optional)
                    </label>
                    <textarea
                        className="form-control massage-control"
                        name="cover_letter"
                        id="cover_letter"
                        cols="30"
                        rows="10"
                        placeholder="Cover Letter"
                    ></textarea>
                </div>
                <div className="col-12 col-sm-12 mb-12">
                    <input type="checkbox" id="agree" name="agree" required />
                    <span className="form-label">
                        &#160;By checking this box, you agree to receive text
                        messages from BunnySpire LLC regarding your hiring
                        process at the number provided. Message frequency may
                        vary. Reply STOP to opt out at any time. Msg and data
                        rates may apply. View our&#160;
                        <Link
                            className="footer-link"
                            to={process.env.PUBLIC_URL + "/terms-conditions"}
                        >
                            Terms
                        </Link>{" "}
                        &{" "}
                        <Link
                            className="footer-link"
                            to={process.env.PUBLIC_URL + "/privacy-policy"}
                        >
                            Privacy Policy
                        </Link>
                        .
                    </span>
                    {errors.resume && <p>{errors.resume.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="applySubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        Apply
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ApplyForm;
