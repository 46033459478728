import React from "react";
import TermsWrap from "../../components/terms/TermsWrap";

const TermsContainer = () => {
    return (
        <div className="faq-section section-py">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10">
                        <TermsWrap />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsContainer;
