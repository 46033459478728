import React from "react";
import PolicyWrap from "../../components/policy/PolicyWrap";

const PolicyContainer = () => {
    return (
        <div className="faq-section section-py">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10">
                        <PolicyWrap />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyContainer;
