import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TermsItem from "./item";
import TermsTitle from "./title";
import TermsContent from "./content";
import { TermsContext } from "./context";

const Terms = ({ children, classOption }) => {
    const [ids, setIds] = useState([]);
    const [activeId, setActiveId] = useState("");
    const [isInit, setIsInit] = useState(true);

    const toggleActive = (id) => {
        if (id === activeId) {
            setActiveId("");
        } else {
            setActiveId(id);
        }
    };

    const setIdsOnInit = (id) => {
        setIds((ids) => [...ids, id]);
    };

    const setActiveIdOnInit = () => {
        if (!activeId) setActiveId(ids[0]);
    };

    useEffect(() => {
        setActiveIdOnInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids.length]);

    return (
        <TermsContext.Provider
            value={{
                ids,
                setIds,
                activeId,
                setActiveId,
                toggleActive,
                setIdsOnInit,
                isInit,
                setIsInit,
            }}
        >
            <div className={`accordion ${classOption}`}>{children}</div>
        </TermsContext.Provider>
    );
};

Terms.propTypes = {
    children: PropTypes.node.isRequired,
    classOption: PropTypes.string,
};
Terms.defaultProps = {
    classOption: "accordion",
};

export { TermsItem, TermsTitle, TermsContent };

export default Terms;
