import React from "react";
import Policy, { PolicyItem, PolicyTitle, PolicyContent } from "../policy";

const PolicyWrap = () => {
    return (
        <Policy classOption="accordion-style2 no-bg">
            <PolicyItem id="one">
                <PolicyTitle id="one">
                    <span className="question-tag">P:</span>Information
                    Collection
                </PolicyTitle>
                <PolicyContent id="one">
                    We collect personal information, including but not limited
                    to names, email addresses, and phone numbers, when you
                    register on our site, place an order, or subscribe to our
                    newsletters.
                </PolicyContent>
            </PolicyItem>
            <PolicyItem id="two">
                <PolicyTitle id="two">
                    <span className="question-tag">P:</span>Use of Information
                </PolicyTitle>
                <PolicyContent id="two">
                    Your information is used to improve customer service,
                    process transactions, and send periodic emails regarding
                    your order or other products and services.
                </PolicyContent>
            </PolicyItem>
            <PolicyItem id="three">
                <PolicyTitle id="three">
                    <span className="question-tag">P:</span>Data Protection
                </PolicyTitle>
                <PolicyContent id="three">
                    We implement a variety of security measures to maintain the
                    safety of your personal information. However, no method of
                    transmission over the internet or method of electronic
                    storage is 100% secure.
                </PolicyContent>
            </PolicyItem>
            <PolicyItem id="four">
                <PolicyTitle id="four">
                    <span className="question-tag">P:</span>Cookies
                </PolicyTitle>
                <PolicyContent id="four">
                    Our site may use cookies to enhance user experience. You can
                    choose to accept or decline cookies.
                </PolicyContent>
            </PolicyItem>
            <PolicyItem id="five">
                <PolicyTitle id="five">
                    <span className="question-tag">P:</span>Third-Party
                    Disclosure
                </PolicyTitle>
                <PolicyContent id="five">
                    We do not sell, trade, or otherwise transfer your personally
                    identifiable information to outside parties without your
                    consent.
                </PolicyContent>
            </PolicyItem>
            <PolicyItem id="six">
                <PolicyTitle id="six">
                    <span className="question-tag">P:</span>Your Rights
                </PolicyTitle>
                <PolicyContent id="six">
                    You have the right to access, correct, or delete your
                    personal information. For any inquiries, please contact us.
                </PolicyContent>
            </PolicyItem>
        </Policy>
    );
};

export default PolicyWrap;
